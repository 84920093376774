<template>
  <div id="step-controller" v-if="currentState !== 'complete'">
    <div class="actions" v-if="!madeRecentRequest">
      <div v-if="currentState !== 'product'">
        <button class="button-back" @click.prevent="stepBack">
          <span class="mobile">Back</span>
          <span class="not-mobile">{{ backText }}</span>
        </button>
      </div>
      <div>
        <button class="button-next" @click.prevent="stepNext">
          <span class="mobile">Next</span>
          <span class="not-mobile">{{ nextText }}</span>
        </button>
      </div>
    </div>
    <div class="no-actions" v-else>
      <small>
        We're sorry, it looks like you've recently submitted a request. Please
        come back again at a later date.
      </small>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'currentState',
      'buttonsForStep',
      'isRoadblocked',
      'madeRecentRequest'
    ]),
    ...mapState(['formState']),
    backText() {
      return this.buttonsForStep.back
    },
    nextText() {
      return this.buttonsForStep.next
    }
  },
  methods: {
    ...mapGetters(['stateRequiresValidation']),
    stepBack() {
      // Moving backwards never requires validation to be fired
      const targetState = this.formState - 1 < 0 ? 0 : this.formState - 1
      this.$emit('emitStepControl', {
        name: this.currentState,
        currentState: this.formState,
        destinationState: targetState,
        requiresValidation: false
      })
    },
    stepNext() {
      // Moving forward MAY require validation
      const targetState = this.formState + 1
      this.$emit('emitStepControl', {
        name: this.currentState,
        currentState: this.formState,
        requiresValidation: this.stateRequiresValidation(this.formState),
        destinationState: targetState
      })
    }
  }
}
</script>

<style lang="scss"></style>
