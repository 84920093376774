<template>
  <div id="backend-error">
    <div class="error-wrapper">
      <div class="error-content">
        We're sorry, an error has occurred and HCP requests are temporarily
        offline. Please try again later.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // mounted() {
  //   // @TODO: this should send an email when things have gone wrong
  // }
}
</script>

<style lang="scss">
#backend-error {
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  .error-wrapper {
    font-size: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
