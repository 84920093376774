<template>
  <div id="request-complete">
    <request-complete />
  </div>
</template>

<script>
import RequestComplete from '@/components/site-specific/RequestComplete'
import { mapActions } from 'vuex'

export default {
  components: { RequestComplete },
  mounted() {
    this.setRecentRequest()
  },
  methods: {
    ...mapActions(['setRecentRequest'])
  }
}
</script>

<style lang="scss"></style>
