<template>
  <div id="site-product">
    <div class="image">
      <picture>
        <source
          media="(min-width: 1200px)"
          srcset="
            @/assets/product-500x500.png   1x,
            @/assets/product-1000x1000.png 2x
          "
        />
        <img
          src="@/assets/product-250x250.png"
          alt=""
          srcset="
            @/assets/product-250x250.png 1x,
            @/assets/product-500x500.png 2x
          "
          width="250"
          height="250"
        />
      </picture>
    </div>
    <div class="text">
      <h2>Order Samples &amp; Coupons</h2>
      <h3>Kerasal® Intensive Foot Repair™</h3>
      <p>
        Thank you for your interest in Kerasal® samples. We send samples to HCP
        offices once a year. Please click below to add your office to our list
        of recipients.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['productCount'])
  },
  data() {
    return {
      productsObj: [
        {
          sku: 'SKU12345',
          quantity: 1,
          name: 'Kerasal Intensive Foot Repair samples'
        }
      ]
    }
  },
  mounted() {
    /**
     * If Products have not been populated to the VueX store yet,
     * populate it with a default value (this is the standard
     * behavior for sites that do NOT allow selection of products
     * and quantities).
     *
     * If this is something that will change over time or allow
     * custom selections, then $emit() after the changes are made
     * and the parent component...
     *   @/components/organisms/Product.vue
     * ...will handle the communication with VueX automatically.
     */
    if (this.productCount === 0) {
      this.$emit('emitProducts', this.productsObj)
    }
  }
}
</script>

<style lang="scss">
@import '../../style/sass-config';
#site-product {
  @include breakpoint($medium) {
    display: flex;
    align-items: center;
    & > div {
      padding: 1rem;
      &.image {
        flex: 0 0 250px;
        @include breakpoint($xlarge) {
          flex: 0 0 350px;
        }
      }
    }
  }
  .image {
    text-align: center;
    @include breakpoint($mobile) {
      padding-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  .text {
    padding: 1rem 1rem 0.5rem;
  }
  picture {
    display: inline-block;
    height: 0;
    padding-bottom: 250px;
    width: 250px;
    @include breakpoint($medium) {
      width: 250px;
      padding-bottom: 250px;
    }
    @include breakpoint($xlarge) {
      width: 350px;
      padding-bottom: 350px;
    }
    img {
      height: auto;
      width: 100%;
    }
  }
  h2 {
    font-size: 1.25rem;
    @include breakpoint($medium) {
      font-size: 1.5rem;
    }
  }
  h3 {
    font-size: 1.125rem;
    @include breakpoint($medium) {
      font-size: 1.25rem;
    }
  }
  p {
    font-size: 1rem;
    @include breakpoint($medium) {
      font-size: 1.125rem;
    }
  }
}
</style>
