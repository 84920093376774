<template>
  <div id="requests-offline">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss"></style>
