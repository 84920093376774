<template>
  <div id="site-complete">
    <p><strong>Thank you for your order</strong></p>
    <p>You will receive an email when your order has shipped.</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss"></style>
