<template>
  <div id="hcpclient">
    <template v-if="!isRoadblocked">
      <div class="wizard-steps">
        <!-- Product loads content from a self-contained file,
        any content changes can be made there.-->
        <product v-if="currentState === 'product'" ref="product" />

        <!-- ProgressBar is a self-contained component -->
        <progress-bar
          v-if="currentState !== 'product' && currentState !== 'complete'"
        />

        <!-- ShippingAddress can accept PREAMBLE or POSTSCRIPT slot content -->
        <shipping-address
          v-if="currentState === 'shipping'"
          ref="shipping"
          @emitLoadingState="manageLoading"
          @emitBackendError="manageBackendError"
        >
          <p slot="preamble">* Required field</p>
          <!--p slot="postscript">Custom shipping postscript text.</p -->
        </shipping-address>

        <!-- Questions loads content from a self-contained file,
        any content changes can be made there. -->
        <questions v-if="currentState === 'shipping'" ref="questions" />

        <!-- Medproid can accept PREAMBLE or POSTSCRIPT slot content -->
        <medproid
          v-if="currentState === 'medproid'"
          ref="medproid"
          @emitLoadingState="manageLoading"
          @emitBackendError="manageBackendError"
        >
          <p slot="preamble">
            Licensing information is used solely to verify your status as a
            United States healthcare professional so that your order may be
            processed. It will not be shared with third parties.
          </p>
          <!--p slot="postscript">Custom medproid postscript text.</p -->
        </medproid>

        <!-- ReviewRequest can accept PREAMBLE or POSTSCRIPT slot content -->
        <review-request
          v-if="currentState === 'review'"
          ref="review"
          @emitLoadingState="manageLoading"
          @emitBackendError="manageBackendError"
        >
          <p slot="preamble">
            Please confirm your shipping information and email address.
          </p>
          <!--p slot="postscript">Custom review postscript text.</p -->
        </review-request>

        <!-- RequestComplete loads content from a self-contained file,
        any content changes can be made there. -->
        <request-complete v-if="currentState === 'complete'" ref="complete" />
      </div>
      <step-controller @emitStepControl="manageStepControl" />
    </template>
    <template v-else>
      <blacklisted>
        <div class="roadblocked" style="text-align:center">
          <small
            >We're sorry, HCP requests are not available at this time.</small
          >
        </div>
      </blacklisted>
    </template>
    <transition name="fade">
      <loading v-if="isLoading"
        ><span>Loading, one moment, please.</span></loading
      >
    </transition>
    <transition name="fade">
      <backend-error v-if="isError" />
    </transition>
  </div>
</template>

<script>
import Product from '@/components/organisms/Product'
import ProgressBar from '@/components/organisms/ProgressBar'
import RequestComplete from '@/components/organisms/RequestComplete'
import ReviewRequest from '@/components/organisms/ReviewRequest'
import StepController from '@/components/organisms/StepController'
import Blacklisted from '@/components/organisms/Blacklisted'
import Loading from '@/components/organisms/Loading'
import BackendError from '@/components/organisms/BackendError'

import Medproid from '@/components/forms/Medproid'
import Questions from '@/components/forms/Questions'
import ShippingAddress from '@/components/forms/ShippingAddress'

import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BackendError,
    Blacklisted,
    Loading,
    Medproid,
    Product,
    ProgressBar,
    Questions,
    RequestComplete,
    ReviewRequest,
    ShippingAddress,
    StepController
  },
  computed: {
    ...mapGetters(['isRoadblocked', 'currentState'])
  },
  data() {
    return {
      isLoading: true,
      isError: false
    }
  },
  mounted() {
    if (!this.isRoadblocked) {
      this.isLoading = true
      this.axios
        .post('https://kerasalhcp.pml.dev/api/ip', {})
        .then(response => {
          this.setIp(response.data)
        })
        .catch(error => {
          this.isError = true
        })
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions(['setIp', 'setFormState']),
    manageBackendError(payload) {
      this.isError = true
      this.isLoading = false
    },
    manageStepControl(payload) {
      if (payload.requiresValidation) {
        this.$refs[payload.name].validateStep()
      } else {
        this.setFormState(payload.destinationState)
      }
    },
    manageLoading(payload) {
      this.isLoading = payload.isLoading
    }
  }
}
</script>

<style lang="scss">
/**
 * Shared SASS configuration settings
 * - Imports Breakpoint-Sass
 * - Defines breakpoint dimensions
 */
@import 'style/sass-config';
/**
 * Shared CSS transition settings
 */
@import 'style/transitions';

/* SHARED STRUCTURAL STYLES */
#hcpclient {
  background-color: #fff;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  position: relative;
  border: 1px solid $fineBorders;
  border-radius: 0.5rem;
  margin: 0 auto 2rem;
  @include breakpoint($medium) {
    margin: 1rem auto 2rem;
  }
  .form-row {
    @include breakpoint($medium) {
      display: flex;
      & > * {
        flex: 1;
        padding: 0 0.5rem;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .form-item {
    margin-bottom: 1rem;
    label {
      display: block;
      font-weight: bold;
      margin-bottom: 0.25rem;
    }
    .help-text {
      font-size: 0.66rem;
      padding-top: 0.25rem;
    }
  }
  .actions {
    display: flex;
    & > div {
      flex: 1;
      padding: 0 0.5rem;
      &:first-child {
        text-align: left;
        padding-left: 0;
      }
      &:last-child {
        text-align: right;
        padding-right: 0;
      }
    }
  }
  form {
    padding: 1rem;
  }
  #questions {
    display: none;
    // border-top: 1px solid $fineBorders;
    // padding: 1rem 1rem 0.5rem;
  }
  .no-actions {
    text-align: center;
  }
  .warning {
    background-color: $red;
    color: #fff;
    font-size: 1.125rem;
    text-align: center;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
  }
  .progress-bar {
    display: none;
    @include breakpoint($medium) {
      font-size: 0.75rem;
      margin: 1rem;
      display: block;
      ol {
        list-style: none;
        margin: -1rem -0.5rem 0;
        padding: 0.5rem;
        display: flex;
        & > li {
          flex: 1;
          padding: 0.5rem;
          text-align: center;
          background-color: $offWhite;
          border: 1px solid $fineBorders;
          color: $text;
          margin: 0.5rem;
          border-radius: 2rem;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          &.active {
            background-color: $blue;
            border-color: $blue;
            color: #fff;
          }
        }
      }
    }
  }
  label.required {
    &:after {
      content: '*';
      color: $redBright;
      margin-left: 0.25rem;
    }
  }
  #step-controller {
    border-top: 1px solid $fineBorders;
    padding: 1rem;
    button {
      background: $red;
      border-radius: 2rem;
      border-style: none;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      span.mobile {
        display: inline;
        @include breakpoint($medium) {
          display: none;
        }
      }
      span.not-mobile {
        display: none;
        @include breakpoint($medium) {
          display: inline;
        }
      }
    }
  }
  #requests-offline {
    padding: 2rem;
  }
  #review-request {
    padding: 1rem;
    .answers {
      display: none;
    }
  }
  #request-complete {
    padding: 1rem;
  }
}
</style>
