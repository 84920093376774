<template>
  <div id="questions">
    <questions @emitQuestions="manageQuestions" />
  </div>
</template>

<script>
import Questions from '@/components/site-specific/Questions'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      questions: []
    }
  },
  components: {
    Questions
  },
  methods: {
    ...mapActions(['setQuestions']),
    manageQuestions(payload) {
      this.setQuestions(payload)
    }
  }
}
</script>

<style lang="scss"></style>
