<template>
  <div id="review-request">
    <div class="preamble"><slot name="preamble"></slot></div>
    <div class="warning" v-if="missingFields">
      Please verify that you have entered all required information.
    </div>
    <div class="warning" v-if="invalidCredentials">
      Please check your MedProID credentials and try again.
    </div>
    <div class="review-items">
      <div class="shipping">
        <strong>Shipping to:</strong>
        <address>
          {{ medproid.firstName }} {{ medproid.lastName }}<br />
          {{ shipping.practice }}<br />
          {{ shipping.address1 }}<br />
          <template v-if="shipping.address2"
            >{{ shipping.address2 }}<br
          /></template>
          {{ shipping.city }}, {{ shipping.state }} {{ shipping.zip }}
        </address>
      </div>
      <div class="products">
        <strong>Products in your request:</strong>
        <ul>
          <li v-for="(product, index) in products" :key="index">
            {{ product.name }} &times;{{ product.quantity }}
          </li>
        </ul>
      </div>
      <div class="answers">
        <strong>Your answers:</strong>
        <ul>
          <li v-for="(question, index) in questions" :key="index">
            <strong>{{ question.q }}:</strong>
            {{ question.a ? question.a : 'no response' }}
          </li>
        </ul>
      </div>
    </div>
    <div class="postscript"><slot name="postscript"></slot></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState(['shipping', 'medproid', 'questions', 'products'])
  },
  data() {
    return {
      missingFields: false,
      invalidCredentials: false
    }
  },
  methods: {
    ...mapActions(['setRequest']),
    validateStep() {
      this.$emit('emitLoadingState', { isLoading: true })
      this.axios
        .post('https://kerasalhcp.pml.dev/api/request', {
          products: this.products,
          address: this.shipping,
          medpro: this.medproid,
          questions: this.questions
        })
        .then(response => {
          // @TODO: check for failed states here...
          // this.missingFields = response.data.missingFields
          // this.invalidCredentials = response.data.isInvalid
          this.setRequest(response.data)
          this.$emit('emitLoadingState', { isLoading: false })
        })
        .catch(error => {
          this.$emit('emitBackendError', { isError: true })
          this.$emit('emitLoadingState', { isLoading: false })
        })
    }
  }
}
</script>

<style lang="scss">
.review-items {
  & > div {
    margin-bottom: 1rem;
  }
}
</style>
