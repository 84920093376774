<template>
  <div id="site-questions"></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getQuestions'])
  },
  data() {
    return {
      questions: []
    }
  },
  mounted() {
    if (this.getQuestions) {
      this.questions = this.getQuestions
    } else {
      this.emitQuestions()
    }
  },
  methods: {
    emitQuestions() {
      this.$emit('emitQuestions', this.questions)
    }
  }
}
</script>

<style lang="scss"></style>
