<template>
  <div class="progress-bar" v-if="currentState !== 'complete'">
    <ol>
      <li :class="{ active: currentState === 'shipping' }">
        Enter shipping information
      </li>
      <li :class="{ active: currentState === 'medproid' }">
        Verify your credentials
      </li>
      <li :class="{ active: currentState === 'review' }">
        Review and submit request
      </li>
    </ol>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentState'])
  }
}
</script>

<style lang="scss"></style>
